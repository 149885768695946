/* Header Styles */
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:  var(--theme-color) ;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.admin-header-left {
  display: flex;
  align-items: center;
}

.admin-sidebar-toggle {
  cursor: pointer;
  font-size: 24px;
}

/* .admin-company-logo {
  height: 55px;
  margin-left: 10px;
  width: 140px;
} */

.admin-header-right {
  display: flex;
  align-items: center;
}

.admin-header-icons {
  display: flex;
  align-items: center;
}

.admin-nav-icon-container {
  position: relative;
  margin-left: 20px;
}

.admin-nav-icon {
  font-size: 18px;
  color: #333;
  cursor: pointer;
}

.admin-nav-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.admin-nav-profile {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.admin-nav-profile-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.admin-nav-caret-icon {
  font-size: 16px;
  color: #333;
}

.admin-nav-profile-dropdown {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border-radius: 5px;
  width: 200px;
  z-index: 1001;
}

.admin-nav-profile-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.admin-nav-profile-item {
  padding: 8px 0;
  cursor: pointer;
}

.admin-nav-profile-item:hover {
  background-color: #f1f1f1;
}

/* Sidebar Styles */
.admin-sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  background:  var(--theme-color)  ;
  color: #464343;
  transition: width 0.3s ease;
  overflow-y: auto;
  padding-top: 20px;
  width: 220px;
  z-index: 999;
}

.admin-sidebar.collapsed {
  
      width: 78px;
  
}

.admin-position-sticky {
  position: sticky;
  top: 0;
}

.admin-nav-item {
  list-style: none;
}

.admin-nav-item .nav-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 15px 20px;
  transition: background-color 0.3s ease;
}

.admin-nav-item .nav-link:hover {
  background-color: linear-gradient(90deg, #ff8757, #ff5757);
}

.admin-nav-icon {
  margin-right: 10px;
}

.link_text {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
  color: #000;
}

.admin-nav-item.active .link_text {
  color: rgb(24, 37, 102);
}

.admin-nav-item.active .nav-link {
  background-color: white;
  color: rgb(24, 37, 102);
}

.admin-nav-item.active.link_text {
  background-color: white;
  color: rgb(24, 37, 102);
}

/* Sidebar toggle icon inside the header */
.admin-sidebar-toggle {
  cursor: pointer;
  font-size: 24px;
  color: #0a98d8;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c1a1a;
  padding: 5px;
  border-radius: 5px;
}

.toggle-icon {
  font-size: 24px;
}

/* Add Leads Button Styles */
.lead-button {
  position: relative;
  background-color: #ff5757;
  color: white;
  border: none;
  /* border-radius: 50%; */
  width: 270px;
  height: 50px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  font-weight: bold;
}

.lead-button:hover {
  background-color: #ff3b3b;
}

/* Responsive Design */
@media (max-width: 767px) {
  .admin-sidebar {
      display: none;
  }

  .admin-sidebar.collapsed {
      display: block;
      width: 50px;
  }

  .admin-sidebar.collapsed .admin-nav-link .link_text {
      display: none;
  }
  .admin-company-logo {
    width: 121px;
    margin-left: 5px;
  }

  .user-admin{
    margin-left: 35px;
    font-size: 20px;
  }
  
}



@media (max-width: 375px){


  .admin-header-right{
    margin-left: -10px;
  }

  .user-admin {
    margin-left: 5px;
    font-size: 20px;
}


.admin-nav-icon {
  margin-left: 5px;
  margin-right: -14px;
}
}
