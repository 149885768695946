


.email-chat-container {
    display: flex;
    flex-direction: row;
  }
  
  .chat-content {
    margin-top: 60px; /* Adjust based on header height */
    margin-left: 220px; /* Adjust based on sidebar width */
    padding: 20px;
    width: calc(100% - 220px); /* Adjust width based on sidebar width */
    transition: margin-left 0.3s ease, width 0.3s ease;
  }
  
  .chat-content.collapsed {
    margin-left: 100px; /* Adjust based on collapsed sidebar width */
    width: calc(100% - 50px); /* Adjust width based on collapsed sidebar width */
  }
  

  /* Responsive Design */
  @media (max-width: 767px) {
    .email-chat-container {
      flex-direction: column;
    }
  
    .chat-content {
      margin-left: 0;
      width: 100%;
    }
  
    .chat-content.collapsed {
      margin-left: 0;
      width: 100%;
    }
  }
  

  .Sales-myteam-container {
    max-width: 1500px;
    margin: auto;
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
    background-color: rgba(163, 110, 41, 0.08);
  }
  
  .lead-details-header {
    background: var(--theme-color);
    color: white;
    padding: 15px 20px;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .indetail-container{
    max-width: 1500px;
    margin: auto;
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
    background-color: rgba(163, 110, 41, 0.08);
  
  }
  /* .back-button{
    display: flex;
    justify-content: flex-end;
  } */


.back-button .btn-secondary {
    padding: 8px 30px;
    border-radius: 4px;
    cursor: pointer;
  }
  .email-chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .chat-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .chat-box {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 10px;
  }
  .message {
    padding: 8px 12px;
    margin: 5px;
    border-radius: 8px;
    max-width: 70%;
  }
  .message.me {
    background: #dcf8c6;
    align-self: flex-end;
  }
  .message.other {
    background: #fff;
    align-self: flex-start;
    border: 1px solid #ddd;
  }
  .chat-input {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #fff;
    border-top: 1px solid #ddd;
  }
  .chat-input input, .chat-input textarea {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .chat-input button {
    padding: 8px 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }