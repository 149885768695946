/* Default styling (for desktop) remains unchanged */
.dataTable_search {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      
      .search-input {
        max-width: 200px;
        flex: 1;
      }
      
      .date-filters {
        display: flex;
        gap: 10px;
      }
      
      /* .date-input {
        max-width: 150px;
      } */
      
      .apply-btn {
        color: white;
        background-color: #ff5e62;
        border-color: #ff5e62;
      }
      
      /* For Tablets (768px - 1024px) */
      @media (min-width: 768px) and (max-width: 1024px) {
        .dataTable_search {
          flex-wrap: wrap;
          justify-content: flex-start;
          /* margin-left: 100px; */
        }
      
        .search-input {
          flex: 1;
        width: 150px;
        }
      
        .date-filters {
          flex-wrap: wrap;
          justify-content: flex-start;
          /* width: 100%; */
        }
      
        .date-input {
          flex: 1;
          width: 140px;
        }
      
        /* .apply-btn {
          flex: 1;
          min-width: 80px;
        } */


        .filter-div{
                margin-top: 100px;
                width: 100px;

        }
      }
      
      /* For Mobile (max-width: 767px) */
      @media (max-width: 767px) {
        .dataTable_search {
          flex-direction: column;
          align-items: stretch;
          gap: 10px;
        }
      
        .search-input {
                width: 147%;
                margin-left: -154px;
            }
      
        /* .clear-btn, .calendar-btn {
          width: 100%;
        } */


        .calendar-btn {
                margin-top: -46px;
                margin-left: 162px;
            }

            .clear-btn{
                margin-top: -46px;
                margin-left: 62px;


            }
        
      
        .date-filters {
          flex-direction: row;
          align-items: stretch;
          width: 100%;
          margin-left: -409px;
        }
      
        .date-input {
          width: 137px;
        }
      
        /* .apply-btn {
          width: 100%;
        } */


        .filter-div{
                margin-top: 120px;
                width: 100px;

        }
      }
      


      @media (max-width: 375px){

        .search-input {
                width: 150%;
                margin-left: -161px;
            }
            .calendar-btn {
                margin-top: -46px;
                margin-left: 136px;
            }


            .date-filters {
                flex-direction: row;
                align-items: stretch;
                width: 100%;
                margin-left: -341px;
            }


            .date-input {
                width: 133px;
                margin-left: -10px;
            }

      }
