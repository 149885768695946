.themes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.themes-box {
  width: 350px;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 60px;
}

.color-selection,
.custom-color {
  margin: 10px 0;
}

.color-dropdown {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.color-picker {
  width: 100%;
  height: 40px;
  border: none;
  cursor: pointer;
}

.color-preview {
  width: 100px;
  height: 40px;
  margin: 10px auto;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  border: 2px solid #ccc;
  transition: 0.3s;
}

.apply-button {
  background: linear-gradient(to right, #ff9966, #ff5e62);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: 0.3s;
}

.apply-button:hover {
  background: linear-gradient(to right, #ff5e62, #ff9966);
}

/* 🔹 Color List Styling */
.color-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 15px 0;
}





.color-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 15px 0;
}

.color-box {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease-in-out, border 0.3s ease-in-out;
  border: 2px solid transparent;
}

.color-box:hover {
  transform: scale(1.1);
}

.color-box.active {
  border: 3px solid #333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.checkmark {
  font-size: 20px;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}