

.salesmyteamcontainer {
    display: flex;
    flex-direction: row;
  }
  
  .salesmyteam {
    margin-top: 60px; /* Adjust based on header height */
    margin-left: 220px; /* Adjust based on sidebar width */
    padding: 20px;
    width: calc(100% - 220px); /* Adjust width based on sidebar width */
    transition: margin-left 0.3s ease, width 0.3s ease;
  }
  
  .salesmyteam.collapsed {
    margin-left: 50px; /* Adjust based on collapsed sidebar width */
    width: calc(100% - 50px); /* Adjust width based on collapsed sidebar width */
  }
  
  .icon-black {
    color: black;
  }
  
  .file-link{
    text-decoration: none;
  }
  
  /* Responsive Design */
  @media (max-width: 767px) {
    .salesmyteamcontainer {
      flex-direction: column;
    }
  
    .salesmyteam {
      margin-left: 0;
      width: 100%;
    }
  
    .salesmyteam.collapsed {
      margin-left: 0;
      width: 100%;
    }
  }
  

  .Sales-myteam-container {
    max-width: 1500px;
    margin: auto;
    padding: 20px;
    margin-top: 10px;
    border-radius: 10px;
    background-color: rgba(163, 110, 41, 0.08);
  }
  