.addleads-form-container {
  width: 100%;
  margin: 20px auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2%;
}

.addleads-form-header {
  background: var(--theme-color);
  color: white;
  padding: 15px 20px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addleads-form-header h2 {
  font-size: 18px;
}

.addleads-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 15px;
}

.addleads-input-group {
  display: flex;
  flex-direction: column;
}

.addleads-input-group label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.addleads-input-group input,
.addleads-input-group textarea,
.addleads-input-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.addleads-form-footer {
  padding: 15px 20px;
  text-align: right;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.addleads-form-footer .btn {
  padding: 8px 30px;
  border-radius: 4px;
  cursor: pointer;
}

.addleads-form-footer .btn-primary {
  background: #f7941e;
  color: white;
}

.addleads-form-footer .btn-secondary {
  background: #6c757d;
  color: white;
}

.addleads-form-footer .btn-primary:hover {
  background: #d6791b;
}

/* Dropdown Styling */
.autocomplete-dropdown {
  position: absolute;
  width: 40%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 100px; /* Scrollable */
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 85px;
}

/* List Items */
.autocomplete-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.autocomplete-dropdown li {
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

/* Hover Effect */
.autocomplete-dropdown li:hover {
  background: #f0f0f0;
}

/* Selected Item Highlight */
.autocomplete-dropdown li.active {
  background: #007bff;
  color: white;
}

/* Error Styling */
/* .error-input {
  border: 1px solid red !important;
} */

/* .error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
} */

@media (max-width: 600px) {
  .addleads-form-grid {
    grid-template-columns: 1fr; 
  }
}

.addleads-input-group.full-width {
  grid-column: 1 / -1;
}