.forgot-container {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    max-width: 1100px;
    width: 90%;
    min-height: 600px;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    margin: auto;
    margin-top: 2%;
  }

  .email-input-wrapper {
    display: flex;
    align-items: center;
  }
  
  .send-otp-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .send-otp-btn:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  
  
  .forgot-image-side {
    position: relative;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("https://marketplace.canva.com/EAFOUzHsGO0/1/0/1067w/canva-brown-vintage-travel-moodboard-photo-collage-93d75-etgmU.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .forgot-image-side::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;
  }
  
  .forgot-logo-container {
    position: relative;
    z-index: 2;
    width: 300px;
    height: 300px;
    background: url("https://marketplace.canva.com/EAFOUzHsGO0/1/0/1067w/canva-brown-vintage-travel-moodboard-photo-collage-93d75-etgmU.jpg")
      center/cover;
    border-radius: 20px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .forgot-logo {
    width: 100%;
    height: auto;
    padding: 1rem;
    background: white;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .forgot-reset-side {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
  }
  
  .forgot-reset-side h1 {
    color: #ff5757;
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .forgot-subtitle {
    color: #666;
    margin-bottom: 2rem;
    font-size: 0.95rem;
  }
  
  .forgot-input-group {
    margin-bottom: 1.2rem;
  }
  
  .forgot-input-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 500;
    font-size: 0.95rem;
  }
  
  .forgot-input-group input {
    width: 100%;
    padding: 0.8rem;
    border: 2px solid #eee;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: all 0.3s;
  }
  
  .forgot-input-group input:focus {
    border-color: #ff5757;
    outline: none;
  }
  
  .forgot-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }
  
  .forgot-remember {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
  }
  
  .forgot-link {
    color: #ff5757;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .forgot-btn {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    background: #ff5757;
    color: white;
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 0.5rem;
  }
  
  .forgot-btn:hover {
    background: #ff4242;
  }
  
  @media (max-width: 768px) {
    .forgot-container {
      grid-template-columns: 1fr;
      height: auto;
      min-height: 600px;
    }
  
    .forgot-image-side {
      padding: 2rem;
      min-height: 300px;
    }
  
    .forgot-logo-container {
      width: 200px;
      height: 200px;
    }
  }
  
  .password-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .toggle-password-btn {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
  }
  
  .toggle-password-btn:hover {
    color: #000;
  }