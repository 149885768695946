.comments-container {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
}

.add-comment {
    display: flex;
    margin-bottom: 20px;
}

.add-comment input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.add-comment button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-comment button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.add-comment button:hover:not(:disabled) {
    background-color: #0056b3;
}

.comments-list {
    margin-top: 10px;
}

.comment {
    background-color: #fff;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.text-muted {
    color: #6c757d;
    font-size: 0.9em;
}


.comment-form-container {
    width: 100%;
    margin: 20px auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 2%;
  }
  
  .comment-form-header {
    background:var(--theme-color);
    color: white;
    padding: 15px 20px;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .comment-close-btn {
    background: #6c757d;
    color: white;
  }


  .comment-btn {
    padding: 8px 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }