.profile-form-container {
    width: 100%;
    
    margin: 20px auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .profile-form-header {
    background: var(--theme-color)    ;
    color: white;
    padding: 15px 20px;
    border-radius: 8px 8px 0 0;
    text-align: center;
  }
  
  .profile-form-header h2 {
    font-size: 18px;
    margin: 0;
  }
  
  .profile-form-body {
    padding: 20px;
  }
  
  .profile-form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  
  .profile-input-group {
    display: flex;
    flex-direction: column;
  }
  
  .profile-input-group label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .profile-input-group input,
  .profile-input-group textarea {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .profile-form-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 15px 20px;
    border-top: 1px solid #ddd;
  }
  
  .profile-btn {
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    font-size: 14px;
  }
  
  .profile-btn-primary {
    background: #f7941e;
    color: white;
  }
  
  .profile-btn-secondary {
    background: #6c757d;
    color: white;
  }
  
  .profile-btn-primary:hover {
    background: #d6791b;
  }
  
  @media (max-width: 600px) {
    .profile-form-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .profile-input-group.full-width {
    grid-column: 1 / -1;
  }
  