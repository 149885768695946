

 /* Main container */
.main-container {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("./../../Images//Wireframe\ -\ 1.jpg") no-repeat center center/cover;
        padding: 20px;
    }
    
    /* Desktop Logo Section */
    .logo-section {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .logo-img {
        width: 570px;
        height: 350px;
        margin-left: -100px;
        transition: transform 0.3s ease;
        border-radius: 17px;
        box-shadow: 0px 4px 23px 14px rgba(0, 0, 0, 0.25);

    }
    
    .logo-img:hover {
        transform: scale(1.05);
    }
    
    /* Login Card */
    .login-card {
        padding: 30px;
        border-radius: 17px;
        box-shadow: 0px 4px 23px 14px rgba(0, 0, 0, 0.25);
        background: white;
        height: 550px;
        background-color: #fffcf5;

    }
    
    /* Mobile & Tablet Logo inside the Card */
    .mobile-logo {
        text-align: center;
        margin-bottom: 1rem;
    }
    
    .mobile-logo .logo-img {
        max-width: 150px;
    }
    
    /* Headings */
    .heading {
        font-size: 30px;
        font-weight: bold;
        margin-top: 20px;

    }
    
    .sub-text {
        font-size: 14px;
    }
    
    /* Google Button */
    .google-btn {
        border: 1px solid #dee2e6;
        border-radius: 8px;
    }
    
    .google-logo {
        width: 35px;
        height: 35px;

    }


    .inputs{
        height: 45px;
    }
    
    /* Links */
    .forgot-password, .signup-link {
        color: #dc3545;
        font-weight: bold;
        text-decoration: none;
    }
    
    /* Responsive Design */
    
    /* Mobile View (max-width: 767px) */
    @media (max-width: 767px) {
        .main-container {
            padding: 15px;
            background-size: cover;
        }
    
        /* Hide Desktop Logo */
        .logo-section {
            display: none;
        }
    
        .login-card {
            padding: 20px;
            box-shadow: 0px 4px 23px 14px rgba(0, 0, 0, 0.25);
            height: 515px;
        }
    
        .mobile-logo .logo-img {
            max-width: 120px;
            box-shadow: none;
            height: 85px;
            margin-left: 10px;

        }
    
        .heading {
            /* font-size: 20px; */
            display: none;

        }
        .sub-text-signup{
                font-size: 13px; 
        }
    
        .sub-text {
            /* font-size: 12px; */
            display: none;

        }
    
        .google-logo {
            width: 30px;
            height: 30px;
        }
    }
    
    /* iPad & Tablet View (768px - 1024px) */
    @media (min-width: 768px) and (max-width: 1024px) {
        .logo-section {
            display: none; /* Hide desktop logo */
        }
    
        .login-card {
            padding: 25px;
            width: 550px;
            margin-left: -100px;
            height: 565px;
            box-shadow: 0px 4px 23px 14px rgba(0, 0, 0, 0.25);

        
        }
    
        .mobile-logo .logo-img {
            max-width: 170px;
            box-shadow: none;
            height: 112px;
            margin-left: 10px;

        }
    
        .heading {
            /* font-size: 22px; */
            display: none;

        }
    
        .sub-text {
            /* font-size: 13px; */
            display: none;

        }
        .sub-text-signup{
                font-size: 15px; 
        }


.google-logo {
    width: 40px;
    height: 40px;
}
    }
    